<template>
    <!-- 
        - This component requires that the data-object sent in has "Language"-field
        - Used in risk setup apps
    -->
    <OModal :name="props.dialogRef" :ref="props.dialogRef" @shown="() => { props.translations_data_object.load(); }" @hidden="onDialogHidden">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("Translations") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="max-height:800px">
                    <ODataGrid
                        :dataObject="props.translations_data_object"
                        hideGridMenu
                        hideMultiselectColumn
                    >   
                        <OColumn field="Language" :headerName="$t('Language')" />
                        <OColumn v-for="(field, index) in props.editableFields" :key="index" :field="field" editable sortable />
                    </ODataGrid> 
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    const props = defineProps({
        "data_object": {
            type: Object,
            req: true
        },
        "translations_data_object": {
            type: Object,
            req: true
        },
        "editableFields": {
            type: Array,
            req: true
        },
        "dialogRef": {
            type: String,
            default: "translationsModal"
        }
    });
    
    const onDialogHidden = async () => {
        if(props.translations_data_object.current.hasChanges) {
            await props.translations_data_object.save();
        }
        props.data_object.refreshRow(props.data_object.current.index);
    }
</script>